import React from "react";
import "./index.css";

const Ultimos = ({ cupos }) => {
  return (
    <div className="relative">
      <div className="container-lg flex flexCol">
        <div className="timerDiv boxShadow">
          <h2>No te lo pierdas.</h2>
          <h2>
            <span>Últimos {cupos} cupos.</span>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Ultimos;
