import React from "react";
import "./index.css";
import logo from "../assets/logo.png";
import logoCons from "../assets/logoCons.svg";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="header flex flexCenter flexRow">
      <div className="logo container-lg flex flexRow">
        <Link to={"/"}>
          <img src={logo} alt="Logo Toyota" />
        </Link>
        <img className="logoCons" src={logoCons} alt="Consesionaria" />
      </div>
    </div>
  );
};

export default Header;
