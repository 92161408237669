import React from "react";
import { useCons } from "../context/ConsContext";
import "./index.css";

const Send = () => {
  const { modelos } = useCons();

  return (
    <div className="fullH send">
      <div className="container-lg">
        <div className="redBox boxShadow flex flexRow">
          <div className="textCol">
            <h1 className="relative">
              ¡Gracias!
              <br />
              <span className="stroke">Recibimos tus datos</span>
            </h1>
            <h3>
              Un representante de Toyota Zento se pondrá en contacto con vos en
              breve.
            </h3>
          </div>
          <div className="modelo">
            <img src={modelos[0].img} alt="Modelo Toyota" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Send;
