import React from "react";
import "./index.css";

import { useCons } from "../context/ConsContext";
import { Link } from "react-router-dom";

const Modelos = ({ title, filter }) => {
  const { buttonData, modelos } = useCons();

  const modelFilter = (modelcar) => {
    return modelcar.filter(({ link }) => {
      return link !== filter;
    });
  };
  const modeloFilter = modelFilter(modelos);

  return (
    <div className="section noPadBot">
      <div className="container-lg flex flexCol">
        <h5 className="textCenter">Elegí tu plan</h5>
        <h2 className="textCenter titleSec">{title}</h2>
        <div className="flex flexRow flexWrap space">
          {modeloFilter.map((modelo, index) => (
            <div className="carCards" key={index}>
              <div className="containCard">
                <h2>
                  Plan {modelo.nombre}
                  {modelo.hybrid && <span className="hibrido">Híbrido</span>}
                </h2>
                <div className="cuota">
                  Cuota<span>${modelo.cuota}</span>
                </div>
                <p className="resal">{modelo.plan}</p>
                <p>{modelo.text1}</p>
                <p>{modelo.text2}</p>
                <p>Valor del Auto: ${modelo.valor}</p>

                <img src={modelo.card} alt={modelo.nombre} />
              </div>
              <div className="buttonDiv">
                <Link className="btn whitebtn" to={"/" + modelo.link}>
                  Conocer más
                </Link>
                <button
                  className="btn botonForm"
                  onClick={() =>
                    buttonData(true, modelo.dataButton, modelo.nombre)
                  }
                >
                  Consultá por {modelo.nombre}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Modelos;
