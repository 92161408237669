import { createContext, useContext, useState } from "react";

/* Imgs */
import yarisImg from "../assets/molelos/yaris.png";
import corollaImg from "../assets/molelos/corolla.png";
import hiluxImg from "../assets/molelos/hilux.png";
import corollacrossImg from "../assets/molelos/corollacross.png";

/* Imgs */
import yariscard from "../assets/molelos/yariscard.png";
import corollacard from "../assets/molelos/corollacard.png";
import hiluxcard from "../assets/molelos/hiluxcard.png";
import hiluxcard44 from "../assets/molelos/hiluxcard44.png";
import corollacrosshybrid from "../assets/molelos/corollacrossxeicard.png";
import corollacrosscard from "../assets/molelos/corollacrosscard.png";

/* Galeria */
/* Yaris */
import yarisGal1 from "../assets/molelos/yaris/yaris1.jpeg";
import yarisGal2 from "../assets/molelos/yaris/yaris2.jpeg";
import yarisGal3 from "../assets/molelos/yaris/yaris3.jpeg";
import yarisGal4 from "../assets/molelos/yaris/yaris4.jpeg";
import yarisGal5 from "../assets/molelos/yaris/yaris5.jpeg";
import yarisGal6 from "../assets/molelos/yaris/yaris6.jpeg";
import yarisGal7 from "../assets/molelos/yaris/yaris7.jpeg";

/* Corolla */
import corollaGal1 from "../assets/molelos/corolla/corolla1.jpeg";
import corollaGal2 from "../assets/molelos/corolla/corolla2.jpeg";
import corollaGal3 from "../assets/molelos/corolla/corolla3.jpeg";
import corollaGal4 from "../assets/molelos/corolla/corolla4.jpeg";
import corollaGal5 from "../assets/molelos/corolla/corolla5.jpeg";
import corollaGal6 from "../assets/molelos/corolla/corolla6.jpeg";

/* Corolla Cross */
import corollacrossGal1 from "../assets/molelos/corollacross/corollacross1.jpeg";
import corollacrossGal2 from "../assets/molelos/corollacross/corollacross2.jpeg";
import corollacrossGal3 from "../assets/molelos/corollacross/corollacross3.jpeg";
import corollacrossGal4 from "../assets/molelos/corollacross/corollacross4.jpeg";
import corollacrossGal5 from "../assets/molelos/corollacross/corollacross5.jpeg";

/* Hilux */
import hiluxGal1 from "../assets/molelos/hilux/hilux1.jpeg";
import hiluxGal2 from "../assets/molelos/hilux/hilux2.jpeg";
import hiluxGal3 from "../assets/molelos/hilux/hilux3.jpeg";
import hiluxGal4 from "../assets/molelos/hilux/hilux4.jpeg";
import hiluxGal5 from "../assets/molelos/hilux/hilux5.jpeg";

export const ConsContext = createContext();

export const useCons = () => {
  const context = useContext(ConsContext);
  if (!context) throw new Error("useCons must be used within a ConsContext");
  return context;
};

export const ConsContextProvider = ({ children }) => {
  const [popActive, setPopActive] = useState({
    active: false,
    clickModel: ""
  });

  const buttonData = (active, clickModel, modelName) => {
    setPopActive({
      active: active,
      clickModel: clickModel ? clickModel : ""
    });
    try {
      dataLayer.push({
        event: "CTA",
        model: modelName ? modelName : "Header"
      });
    } catch (error) {
      console.log(error);
    }
  };

  const prices = {
    yaris70: {
      cuota: "70.032",
      valor: "8.365.000"
    },
    corolla: {
      cuota: "133.168",
      valor: "10.450.000"
    },
    hilux4x2: {
      cuota: "183.738",
      valor: "20.317.000"
    },
    hilux4x4: {
      cuota: "326.965",
      valor: "23.795.000"
    },
    corollacross: {
      cuota: "194.501",
      valor: "15.263.000"
    },
    corollacrosshybrid: {
      cuota: "234.451",
      valor: "18.398.000"
    }
  };

  const modelos = [
    /* {
      id: 227,
      link: "plan-yaris70",
      nombre: "Yaris",
      modelo: "Yaris",
      cuota: prices.yaris70.cuota,
      valor: prices.yaris70.valor,
      plan: "Nuevo plan 70/30",
      text1: "Financiación directa con fábrica",
      text2: "Aplica a beneficio especial",
      img: yarisImg,
      galeria: [
        yarisGal1,
        yarisGal2,
        yarisGal3,
        yarisGal4,
        yarisGal5,
        yarisGal6,
        yarisGal7
      ],
      card: yariscard,
      cupo: 6,
      dataButton: "PLAN YARIS 70/30"
    }, */
    /* {
      id: 2,
      link: "plan-corolla",
      nombre: "Corolla XLI",
      modelo: "Corolla",
      cuota: prices.corolla.cuota,
      valor: prices.corolla.valor,
      plan: "100% en Cuotas",
      text1: "Financiación directa con fábrica",
      text2: "Aplica a beneficio especial",
      img: corollaImg,
      galeria: [
        corollaGal1,
        corollaGal2,
        corollaGal3,
        corollaGal4,
        corollaGal5,
        corollaGal6
      ],
      card: corollacard,
      cupo: 8,
      dataButton: "PLAN COROLLA"
    }, */
    {
      id: 1,
      link: "plan-hilux4x2",
      nombre: "Hilux 4x2 DX",
      modelo: "Hilux",
      cuota: prices.hilux4x2.cuota,
      valor: prices.hilux4x2.valor,
      plan: "Nuevo plan 70/30",
      text1: "Financiación directa con fábrica",
      text2: "Aplica a beneficio especial",
      img: hiluxImg,
      galeria: [hiluxGal1, hiluxGal2, hiluxGal3, hiluxGal4, hiluxGal5],
      card: hiluxcard,
      cupo: 4,
      dataButton: "PLAN HILUX 70/30"
    },
    {
      id: 1,
      link: "plan-hilux4x4",
      nombre: "Hilux 4x4",
      modelo: "Hilux",
      cuota: prices.hilux4x4.cuota,
      valor: prices.hilux4x4.valor,
      plan: "Nuevo plan 100",
      text1: "Financiación directa con fábrica",
      text2: "Aplica a beneficio especial",
      img: hiluxImg,
      galeria: [hiluxGal1, hiluxGal2, hiluxGal3, hiluxGal4, hiluxGal5],
      card: hiluxcard44,
      cupo: 4,
      dataButton: "PLAN HILUX 100"
    }
    /* {
      id: 245,
      link: "plan-corollacross",
      nombre: "Corolla Cross XLI MT",
      modelo: "Corolla Cross",
      cuota: prices.corollacross.cuota,
      valor: prices.corollacross.valor,
      plan: "100% en Cuotas",
      text1: "Financiación directa con fábrica",
      text2: "Aplica a beneficio especial",
      img: corollacrossImg,
      galeria: [
        corollacrossGal1,
        corollacrossGal2,
        corollacrossGal3,
        corollacrossGal4,
        corollacrossGal5
      ],
      card: corollacrosscard,
      cupo: 7,
      dataButton: "PLAN COROLLA CROSS"
    },
    {
      id: 245,
      link: "plan-corollacrossxei",
      nombre: "Corolla Cross XEI HV CVT",
      modelo: "Corolla Cross",
      hybrid: true,
      cuota: prices.corollacrosshybrid.cuota,
      valor: prices.corollacrosshybrid.valor,
      plan: "100% en Cuotas",
      text1: "Financiación directa con fábrica",
      text2: "No paga patente en CABA",
      img: corollacrossImg,
      galeria: [
        corollacrossGal1,
        corollacrossGal2,
        corollacrossGal3,
        corollacrossGal4,
        corollacrossGal5
      ],
      card: corollacrosshybrid,
      cupo: 4,
      dataButton: "PLAN COROLLA CROSS XEI HV CVT"
    } */
  ];

  const modelsOptions = modelos.map((model) => {
    return {
      value: model.dataButton,
      text: `${model.dataButton} (Cuota $${model.cuota})`,
      disabled: model.disabled
    };
  });

  console.log(modelsOptions);

  return (
    <ConsContext.Provider
      value={{
        popActive,
        setPopActive,
        prices,
        buttonData,
        modelos,
        modelsOptions
      }}
    >
      {children}
    </ConsContext.Provider>
  );
};
