import React from "react";
import { useCons } from "../context/ConsContext";
import { useParams } from "react-router-dom";
import Hero from "../Hero";
import Modelos from "../Modelos";
import BodyModel from "./BodyModel";

/* Media */

const ModeloSimple = () => {
  const { modelos } = useCons();

  let { model } = useParams();

  const modelLink = (modelcar) => {
    return modelcar.filter(({ link }) => {
      return link === model;
    });
  };
  const modeloFilter = modelLink(modelos);

  return (
    <>
      {modeloFilter.map((model, index) => (
        <div key={index}>
          <Hero img={model.img} dataButton={model.dataButton} />
          <BodyModel model={model} />
          <Modelos title="Ver más modelos" filter={model.link} />
        </div>
      ))}
    </>
  );
};

export default ModeloSimple;
