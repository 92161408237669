import React from "react";
import "./index.css";
import { useCons } from "../context/ConsContext";

const Hero = ({ img, dataButton }) => {
  const { buttonData } = useCons();

  const month = new Date(new Date()).toLocaleString("es", {
    month: "long"
  });

  return (
    <div className="fullH">
      <div className="container-lg">
        <div className="redBox boxShadow flex flexRow">
          <div className="textCol">
            <h4 className="relative">Solo por {month}</h4>
            <h1 className="relative">
              Plan Llave
              <br />
              <span className="stroke">por llave</span>
            </h1>
            <h3>
              Tomamos tu usado como parte de pago pero lo entregás en el momento
              del patentamiento.
            </h3>
            <button
              className="btn whitebtn"
              onClick={() => buttonData(true, dataButton)}
            >
              Consultar ahora
            </button>
          </div>
          <div className="modelo">
            <img src={img} alt="Modelo Toyota" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
