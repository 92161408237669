import React from "react";
import Ultimos from "../../Ultimos";
import { Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./index.css";
import { useCons } from "../../context/ConsContext";

const BodyModel = ({ model }) => {
  const { buttonData } = useCons();

  return (
    <div className="section noPadTop">
      <div className="container-lg flex flexCol">
        <h1 className="textCenter relative">
          Toyota <span>{model.nombre}</span>
        </h1>
        <h3 className="textCenter">{model.plan}</h3>
        <Ultimos cupos={model.cupo} />
        <div className="galeriaText flex flexRow flexWrap space">
          <div className="galeria">
            <Swiper
              className="galeriaSwiper"
              modules={[Pagination, Autoplay]}
              pagination={{ clickable: true }}
              slidesPerView={1}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false
              }}
            >
              {model.galeria.map((img, index) => (
                <SwiperSlide key={index}>
                  <img src={img} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="textModel flex flexCol justifyCenter">
            <h2>
              Plan {model.nombre}
              {model.hybrid && <span className="hibrido">Híbrido</span>}
            </h2>
            <div className="cuota">
              Cuota<span>${model.cuota}</span>
            </div>
            <p className="resal">{model.plan}</p>
            <p>{model.text1}</p>
            <p>{model.text2}</p>
            <p>Valor del Auto: ${model.valor}</p>

            <button
              className="btn botonForm"
              onClick={() => buttonData(true, model.dataButton, model.nombre)}
            >
              Consultar por este modelo
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BodyModel;
