import React from "react";
import "./index.css";

import logoMarca from "../assets/logoMarca.svg";
import logo from "../assets/logo.png";
import arrow from "../assets/arrow.svg";

const SubFooter = () => {
  return (
    <>
      <div className="befFooter">
        <div className="container-lg colImg flex flexCol textCenter">
          <h5>Tené tu Toyota de forma simple y sin vueltas</h5>
          <h2>Beneficios destacados</h2>
          <p>
            • Financiación del 70% o el 100% hasta 84 cuotas en PESOS y sin
            interés
            <br />
            • 6 Bonifiaciones exclusivas para nuestro clientes
            <br />• 84 Cuotas solo con DNI
          </p>
        </div>
      </div>
      <div className="section befFooter">
        <div className="container-lg flex flexCol flexCenter tpaMod">
          <div className="colImg flex flexRow logosFooter">
            <img src={logoMarca} alt="Logo Marca" />
            <img src={logo} alt="TPA" />
          </div>
          <h4>
            Lograr la máxima satisfacción de nuestros clientes en la experiencia
            de comprar y tener un Toyota
          </h4>
          <div className="col3">
            <div>
              <img src={arrow} alt="Arrow" />
              <p>ATENCIÓN DE EXCELENCIA</p>
            </div>
            <div>
              <img src={arrow} alt="Arrow" />
              <p>10 AÑOS DE EXPERIENCIA</p>
            </div>
            <div>
              <img src={arrow} alt="Arrow" />
              <p>ENTREGA DIRECTA DE FÁBRICA</p>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="container-lg flex flexRow flexWrap space">
          <p>
            <img src={logo} alt="Logo" />
            Concesionario Oficial Toyota -
            <a
              href="https://tengouncero.com.ar/tyc"
              target="_blank"
              style={{ display: "block" }}
              rel="noopener noreferrer"
            >
              Términos y Condiciones
            </a>
          </p>
          <p>
            Mango&amp;Cía
            <a
              href="https://mangoycia.com/?utm_source=partners&amp;utm_medium=zento&amp;utm_campaign=partners"
              target="_blank"
            >
              Agencia de Performance
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default SubFooter;
